module.exports = function(conditional) {
  var operations = {
    'lt': function(left, right) { return left < right; },
    'gt': function(left, right) { return left > right; },
    'lte': function(left, right) { return left <= right; },
    'gte': function(left, right) { return left >= right; },
    'eq': function(left, right) { return left === right; },
    'neq': function(left, right) { return left !== right; },
    'or': function(left, right) { return (left || right)?true:false; },
    'and': function(left, right) { return (left && right)?true:false; },
    'nor': function(left, right) { return (!left && !right)?true:false; },
    'xor': function(left, right) { return (left && !right)?true:false; },
    'xnor':function(left, right) { return !(left && !right)?true:false;},
    'contains': function(left, right) { return left.indexOf(right) !== -1; },
    'type': function(left, right) {
      return (right === 'array' &&
        Object.prototype.toString.call(left) === '[object Array]') ||
      typeof(left) === right;
    },
    'in': function(left, right) {
      if( right && right.indexOf )
        return !!~right.indexOf(left);
      return false;
    },
    /* Not In */
    'nin': function(left, right) {
      if( right && right.indexOf )
        return !~right.indexOf(left);
      return true;
    }
  },
  options = arguments[arguments.length - 1];

  if (arguments.length === 4) {
    var operation = arguments[1];
    conditional = (operations[operation] &&
      operations[operation](conditional, arguments[2]));
  }

  if (conditional) {
    return options.fn(this);
  } else if (options.inverse) {
    return options.inverse(this);
  }
};
